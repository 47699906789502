.btn {
    width: max-content;
    display: inline-block;
    color: $color-primary;
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid $color-primary;
    transition: $transition;
    &:hover {
        background: $color-white;
        color: $color-bg;
        border-color: transparent;
    }
}

.btn-primary {
    background: $color-primary;
    color: $color-bg;
}