.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    & > div {
        background: $color-bg-variant;
        padding: 2.4rem 5rem;
        border-radius: 2rem;
        border: 1px solid transparent;
        transition: $transition;
        &:hover {
            background: $color-primary-variant;
            cursor: default;
        }
        h3 {
            text-align: center;
            margin-bottom: 2rem;
            color: $color-primary;
        }
    }
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-left: 1rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

.experience__details-icon {
    margin-top: 6px;
    color: $color-primary;
}

// Media Queries

// Medium Devices
@media screen and (max-width: 1024px){
    .experience__container {
        grid-template-columns: 1fr;
        & > div {
            width: 80%;
            padding: 2rem;
            margin: 0 auto;
        }
    }

    .experience__content {
        padding: 1rem;
    }
}

// Small Devices
@media screen and (max-width: 600px){
    .experience__container {
        gap: 1rem;
    }

    .experience__container {
        & > div {
            width: 100%;
            padding: 2rem 1.5rem;
            h3 {
                margin-bottom: 1rem;
            }
        }
        h4 {
            font-size: 0.8rem;
        }
    }

    .experience__content {
        gap: 0.5rem 2rem;
    }

    .experience__details-icon {
        margin-top: 0;
    }
}