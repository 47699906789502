.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: $color-bg-variant;
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: $transition;
    &:hover {
        background: $color-primary-variant;
    }
    h3 {
        margin: 1.2rem auto 0.5rem 1rem;
    }
    span {
        font-size: 1.2rem;
    }
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.title__container {
    display: flex;
}

.portfolio__item-cta {
    display: flex;
    margin: auto 1rem;
}

.portfolio__swiper {
    width: 16.5rem;
    font-size: 0.8rem;
    padding-bottom: 2.5rem;
    margin-left: 1rem;
}

// Media Queries

// Medium Devices
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

    .portfolio__swiper {
        width: 18rem;
    }
}

// Small Devices
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .portfolio__swiper {
        width: 18rem;
    }
}