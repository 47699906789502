header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
    .me {
        // background: linear-gradient($color-primary, transparent);
        width: 22rem;
        // height: 30rem;
        position: absolute;
        left: calc(50% - 11rem);
        margin-top: 4rem;
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
        // padding: 5rem 0 0 1.5rem;
    }
}

.header__graphic {
    position: absolute;
    left: calc(50% - 15rem);
    top: 12rem;
    width: 30rem;
    transition: $transition;
}

// CTA
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

// HeaderSocials
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    &::before {
        content: '';
        width: 1px;
        height: 2rem;
        background: $color-primary;
    }
    &::after {
        content: '';
        width: 1px;
        height: 2rem;
        background: $color-primary;
    }
}

// Media Queries

// Medium Devices
@media screen and (max-width: 1024px){
    header {
        height: 100vh;
    }

    .header__graphic {
        left: calc(50% - 12rem);
        top: 14rem;
        width: 25rem;
    }
}

// Small Devices
@media screen and (max-width: 600px){
    header {
        height: 90vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }

    .header__graphic{
        left: calc(50% - 15rem);
        top: 12rem;
        width: 30rem;
    }
}