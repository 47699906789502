.container {
    &.contact__container {
        width: 58%;
        display: grid;
        grid-template-columns: 40% 48%;
        gap: 12%;
    }
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    flex-wrap: wrap;
}

.contact__option {
    background: $color-bg-variant;
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: $transition;
    &:hover {
        background: $color-primary-variant;
    }
    a {
        margin-top: 0.7rem;
        display: inline-block;
        font-size: 0.8rem;
    }
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid $color-primary-variant;
    resize: none;
    color: $color-white;
    font-family: 'Poppins', sans-serif;
    &::placeholder {
        color: $color-primary-variant;
    }
}

// Media Queries

// Medium Devices
@media screen and (max-width: 1024px){
    .container {
        &.contact__container {
            grid-template-columns: 1fr;
            gap: 2rem;
        }
    }
}

// Small Devices
@media screen and (max-width: 600px){
    .container {
        &.contact__container {
            width: $container-width-sm;
            button {
                margin-left: auto;
            }
        }
    }
}