// Import Google fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

// Define fonts for body and headers
$body-font: 'Poppins', sans-serif;
$headers-font: 'Poppins', sans-serif;

// // From a font file
// @font-face {
//     font-family: "FinkHeavy";
//     src: local("FinkHeavy"),
//       url("./fonts/FinkHeavy.ttf") format("truetype");
//     font-weight: normal;
// }