nav {
    top: 0;
    position: sticky;
    height: 0.5rem;
    z-index: 10;
}

.nav__logo-link {
    margin-right: auto;
}

.nav__logo {
    height: 2rem;
    width: 2rem;
    margin-left: 1rem;
    display: block;
    transition: $transition;
    &:hover {
        transform: scale(1.3);
    }
}

.nav__md-lg {
    width: 100%;
    display: block;
    padding: 0.7rem 1.7rem;
    display: flex;
    align-items: center;
    position: absolute;
    transition: $transition;
    .nav__icon {
        padding: 0.5rem 2rem;
        font-size: 1.1rem;
        border-bottom: 1px solid $color-primary;
    }
}

.nav__scroll {
    background: rgba(79, 97, 125, 0.3);
    z-index: 10;
    backdrop-filter: blur(15px);
    .nav__icon {
        border-bottom: none;
    }
}

.nav__mobile {
    display: none !important;
    background: rgba(79, 97, 125, 0.3);
    width: max-content;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    a {
        background: transparent;
        padding: 0.9rem;
        border-radius: 50%;
        display: flex;
        color: $color-primary;
        font-size: 1.1rem;
        &.active {
            background: $color-primary-variant;
            color: $color-white;
        }
    }
}

// Media Queries

// Medium Devices
@media screen and (max-width: 1024px){
    .nav__mobile {
        display: none !important;
    }
}

// Small Devices
@media screen and (max-width: 600px){
    .nav__md-lg {
        display: none;
    }

    .nav__mobile {
        display: block !important;
        display: flex !important;
        // position: absolute;
        // bottom: 2rem;
    }

    nav {
        top: 0;
        position: relative;
        height: 0.5rem;
    }
}