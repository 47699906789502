footer {
    margin: 7rem 7rem 0 2rem;
    width: auto;
    position: relative;
}

.footer__divide {
    width: 30rem;
    border-top: 1px solid $color-primary;
}

.footer__logo {
    margin-top: 2rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    height: 4rem;
    width: 4rem;
    display: block;
    transition: $transition;
    &:hover {
        transform: scale(1.3);
    }
}

.footer__socials {
    display: flex;
    margin-left: 2rem;
    gap: 1rem;
    margin-bottom: 1rem;
    a {
        background: $color-bg-variant;
        color: $color-light;
        padding: 0.8rem;
        border-radius: 0.7rem;
        display: flex;
        border: 1px solid transparent;
        &:hover {
            background: $color-primary-variant;
        }
    }
}

.footer__copyright {
    margin-bottom: 3rem;
    margin-left: 2rem;
    color: $color-bg-variant;
}

.footer__graphic {
    position: absolute;
    right: -4rem;
    bottom: -2rem;
    width: 25rem;
    transition: $transition;
}

// Media Queries

// Medium Devices
@media screen and (max-width: 1024px){
    .footer__graphic {
        width: 15rem;
    }
}

// Small Devices
@media screen and (max-width: 600px){
    footer {
        margin-right: 3rem;
        margin-left: 1.5rem;
        margin-bottom: 8rem;
    }

    .footer__logo {
        margin-left: 0;
    }

    .footer__socials {
        margin-left: 0;
        z-index: 3;

    }

    .footer__copyright {
        margin-left: 0;
    }

    .footer__divide {
        width: 15rem;
    }

    .footer__graphic {
        right: -2rem;
        bottom: -9rem;
        width: 16rem;
        overflow: hidden;
        z-index: 1;
    }
}