.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
    padding-bottom: 4rem;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        $color-bg-variant,
        transparent
    );
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: $transition;
    &:hover {
        transform: rotate(0);
    }
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: $color-bg-variant;
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: $transition;
    margin-bottom: 1.5rem;
    h5 {
        font-size: 0.95rem;
        color: $color-white;
    }
    small {
        font-size: 0.7rem;
        color: $color-light;
    }
    &:hover {
        background: $color-primary-variant;
        cursor: default;
    }
}

.about__icon {
    color: $color-primary;
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__content {
    p {
        margin: 1rem 0 0;
        color: $color-white;
        font-weight: 300;
    }
    a {
        margin-top: 2rem;
    }
}

.about__swiper {
    padding-bottom: 3rem;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: $color-light;
}

// Media Queries

// Medium Devices
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        grid-template-columns: 100%;
        gap: 0;
        padding-bottom: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content {
        text-align: center;
        p {
            margin: 2rem 0 1.5rem;
        }
    }

    .about__swiper {
        width: 100%;
        padding-bottom: 1rem;
    }
}

// Small Devices
@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__card {
        margin-bottom: 0;
    }

    .about__swiper {
        width: 100%;
    }
}